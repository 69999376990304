import React, { Component } from "react"
import { config } from "../helpers/clientConfig"

const sanityClient = require('@sanity/client')

const client = sanityClient({
  projectId: config.projectId,
  dataset: config.dataset,
  useCdn: false, // `false` if you want to ensure fresh data
  withCredentials: true
})

class PreviewPage extends Component {
  state = {
    document: null,
    type: null
  }

  componentDidMount () {
    let location = this.props.location
    let pathname = location.pathname.split('/')
    let type = pathname[pathname.length - 2]
    let id = pathname[pathname.length - 1]
    let query = this.setQuery(type, id)

    client.fetch(query, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
      this.setState({
        type: response[0]._type,
        document: response[0]
      })
    }).catch(error => {
      console.log('problem', error)
    })
  }

  setQuery = (type, id) => {
    switch (type) {
      case 'blogInsight':
      case 'blogTech':
      case 'caseStudy':
      case 'resource':
        return id = `*[_id == "${id}"]{..., category->{...}}`

      case 'thankYouPage':
        return id = `*[_id == "${id}"]`

      case 'page':
        return id = `*[_id == "${id}"]{..., parent->{...}}`

      default:
        return null
    }
  }

  renderPreview() {
    const remoteURL = "https://ltg-watershed.herokuapp.com"
    const localURL = "http://localhost:8000"
    const baseURL = typeof window !== `undefined` && window.location.hostname === "localhost" ? localURL : remoteURL

    if (this.state.document) {
      switch (typeof window !== `undefined` && this.state.type) {
        case 'blogInsight':
          return window.location = `${baseURL}/blog/${this.state.document.category.slug.current}/${this.state.document.slug.current}`

        case 'blogTech':
          return window.location = `${baseURL}/blog/product/${this.state.document.category.slug.current}/${this.state.document.slug.current}`

        case 'resource':
          return window.location = `${baseURL}/resources/${this.state.document.category.slug.current}/${this.state.document.slug.current}`

        case 'caseStudy':
          return window.location = `${baseURL}/case-studies/${this.state.document.category.slug.current}/${this.state.document.slug.current}`

        case 'thankYouPage':
          return window.location = `${baseURL}/thank-you/${this.state.document.slug.current}`

        case 'page':
          let url = this.state.document.parent ? `${baseURL}/${this.state.document.parent.slug.current}/${this.state.document.slug.current}` : `${baseURL}/${this.state.document.slug.current}`
          return window.location = url

        default:
          break
      }
    }
  }

  render() {
    return (
      <div>{this.renderPreview()}</div>
    )
  }
}

export default PreviewPage
